import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "First Thoughts On The 27'' LG UltraGear 4K 1ms 144Hz G-Sync Gaming Monitor",
  "date": "2020-11-14",
  "slug": "testing-the-27-lg-ultragear-4k-1ms-144hz-g-sync-gaming-monitor",
  "featuredImage": "./images/monitor.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’ve spent two weeks in the 4k 144hz world and I couldn’t be more satisfied. Let’s start with a spec rundown for the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/LG-27GN950-B-Ultragear-Response-Compatibility/dp/B08BCRYS6B/ref=as_li_ss_tl?ie=UTF8&linkCode=ll1&tag=addtocart098-20&linkId=64f14ea02972188bd8b199032e154904&language=en_US"
      }}>{`27'' LG UltraGear 4K 1ms 144Hz G-Sync Gaming Monitor`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`27" UHD (3840 x 2160) Nano IPS Display`}</li>
      <li parentName="ul">{`IPS 1ms (GtG) 144Hz with VESA DSC Technology`}</li>
      <li parentName="ul">{`NVIDIA G-SYNC Compatible with AMD FreeSync Premium Pro`}</li>
      <li parentName="ul">{`DCI-P3 98% with VESA DisplayHDR 600`}</li>
      <li parentName="ul">{`4-Side Virtually Borderless Display`}</li>
      <li parentName="ul">{`Tilt/Height/Pivot Adjustable Stand`}</li>
    </ul>
    <p>{`I’m coming from an `}<a parentName="p" {...{
        "href": "https://www.amazon.com/PG279Q-DisplayPort-Adjustable-Ergonomic-EyeCare/dp/B017EVR2VM/ref=as_li_ss_tl?ie=UTF8&linkCode=ll1&tag=addtocart098-20&linkId=c4159953b1622a81776207964a368ed8&language=en_US"
      }}>{`Asus ROG Swift PG279Q 27"`}</a>{` and have use cases for gaming, photo editing, and work tasks that involve looking at text all day. The 4k upgrade made the most difference in more common productivity tasks since pixel blur was eliminated. The pixel density is high enough to smooth things out like nicer Macbook Pro displays and I feel less eye fatigue. I couldn’t tell the difference between 165hz and 144hz while gaming and felt like I was hitting shots in Overwatch because I could see targets peeking at me in higher resolution, whereas with 1440p I couldn't see the crisp outline of the target. Tracking targets also felt better in higher resolution. I could be imagining this! These are just my initial feelings.`}</p>
    <p>{`Beyond obvious resolution differences, the color accuracy seems greatly improved and brightness slightly improved. The brightness jump was supposed to be doubled (300 nits to 600 nits) but only feels like 20%. I think this is some of that fake HDR marketing kicking in that promises max brightness on certain parts of the screen that don’t apply to the whole screen. I wish they advertised this a bit better. The main reason I upgraded is because this is the first LG monitor I could find under $1,000 (It was $800) and it met all of my needs. If you’re using your computer to work from home, game, or complete creative tasks e.g. photo editing - I’d highly recommend this monitor as a value choice to meet all of these use cases. Other models ranging $1500+ add a fancy “FALD” backlight in certain cases with better HDR and I’m glad I haven’t tried them in person. I bet it’d hurt my wallet more than my end satisfaction. `}</p>
    <p>{`In the next year or so I expect more advanced micro LED HDR 4k monitors to launch. I’m excited about the prospect, but am not thrilled about the forecasted astronomical prices. I feel like this LG will be a great 2-3 year holdover to step into a model with a more epic backlight / HDR capability in the future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      